.login .default-card {
  min-width: 500px !important;
  padding: 40px !important;
}

/* .login .ant-card {
    margin-top: 120px !important
} */

.login .ant-card-body {
  padding: 20px 0px !important;
  margin-top: 10px !important;
}

.login .ant-input-affix-wrapper {
  border-radius: 5px;
}

.login .ant-card-bordered .ant-card-cover {
  margin-bottom: 25px !important;
}

.login .ant-btn-primary {
  width: 100%;
}

.login {
  height: 100vh;
  background-image: url("../../../Assests/Images/background.jpg");
}
