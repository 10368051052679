.toolbar{
    border-bottom: 1px solid #eeeeee;
    background: #ffffff;
    position: fixed;
    width: 100%;
    height: 70px;
    overflow: hidden;
    top: 0;
    z-index: 998;
}

.toolbar .ant-menu-horizontal{
    margin-top: -2px;
}